<template>
  <v-expansion-panels
    :data-testid="`hub-profile-history-table-${index}-container`"
    class="shadow-regular"
    :value="open"
    flat
  >
    <v-expansion-panel class="rounded-lg px-2 py-4">
      <v-expansion-panel-header class="d-flex align-center heading-h3 mb-4">
        <span :data-testid="`hub-profile-history-table-${index}-title`">
          {{ t(`Title_.subTitle.${tableName}`) }}
        </span>
        <template #actions>
          <v-icon
            :data-testid="`hub-profile-history-table-${index}-chevron-icon`"
            color="#212121"
          >
            mdi-chevron-down
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-container class="px-0" v-if="tableContent.length !== 0">
          <v-row
            :data-testid="`hub-profile-history-table-${index}-header-container`"
            class="overline-1 greyRegular--text"
          >
            <v-col cols="3">{{ t('Msg_.date') }}</v-col>
            <v-col cols="9">{{ t('Msg_.changeTo') }}</v-col>
          </v-row>
          <v-row
            v-for="(data, i) in tableContent"
            :key="i"
            class="element-border"
            :data-testid="`hub-profile-history-table-${index}-line-${i}`"
          >
            <v-col
              :data-testid="`hub-profile-history-table-${index}-line-${i}-date`"
              cols="3"
              class="body-text-4"
            >
              {{ helpers.formatDate(data.modifiedAt) }}
            </v-col>
            <v-col
              :data-testid="`hub-profile-history-table-${index}-line-${i}-value`"
              cols="9"
              class="body-text-4"
            >
              {{ data.newValue }}
            </v-col>
          </v-row>
        </v-container>
        <v-container v-else class="pa-0 body-text-2">
          <v-row
            dense
            :data-testid="`hub-profile-history-table-${index}-empty-table-message`"
          >
            <v-col>
              {{ t('Msg_.emptyHistoryTable') }}
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup>
import languages from '../../locales/languages';
import helpers from '@/helpers/helpers';
import { useI18n } from 'vue-i18n-bridge';
import { ref, defineProps } from 'vue';

const { t } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  tableName: {
    type: String,
    required: true,
  },
  tableContent: {
    type: Array,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
});

// --- Local variables ---
const open = ref(0);
</script>

<style scoped lang="scss">
.element-border {
  border-top: 1px solid $ash-regular;
  border-radius: 4px;
}
</style>
