<template>
  <v-container data-testid="hub-profile-history-container" class="pa-0">
    <v-row v-for="(table, key, index) in hubHistory" :key="key" no-gutters>
      <v-col>
        <HubProfileHistoryTable
          :table-name="key"
          :table-content="table"
          :index="index"
          class="mb-8"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import HubProfileHistoryTable from './hub-profile-history-table';
import { useRoute } from 'vue-router/composables';
import { useHubsStore } from '@/store/pinia/hubs.store.js';
import { ref } from 'vue';

const route = useRoute();
const hubsStore = useHubsStore();

// --- Local variables ---
const hubHistory = ref(null);

// --- Methods ---
const getHubHistoryById = async () => {
  try {
    hubHistory.value = await hubsStore.getHubHistoryById(route.params.hubId);
  } catch (error) {
    console.log(error);
  }
};

// --- Lifecycle hooks ---
getHubHistoryById();
</script>

<style scoped lang="scss"></style>
